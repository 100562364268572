import React, { useContext, useState, useEffect, useLayoutEffect } from 'react'
import axios from 'axios';
import qs from 'qs';
import { useStaticQuery, graphql } from 'gatsby'
import navigate from '../utils/navigate'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Context as UserContext } from '../components/UserContext'
import random from '../utils/random';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexPage {
      site {
        siteMetadata {
          CBC_ENV
          home
          pathPrefix
          benefitPortalURL
          defaultUser {
            username
            password
          }
          loginURL
          forgotPasswordURL
          addLeaveRequestURL
          ssoURL
        }
      }
    }
  `)
  const {
    CBC_ENV,
    home,
    pathPrefix,
    benefitPortalURL,
    defaultUser,
    loginURL,
    // forgotPasswordURL,
    ssoURL,
  } = data.site.siteMetadata

  const [state, setstate] = useState({
    Loading: false,
    Username: defaultUser.username,
    Password: defaultUser.password,
    Error: '',
  })

  const { login } = useContext(UserContext)
  let token = null

  useLayoutEffect(() => {
    const url_string = window.location.href
    const url = new URL(url_string);
    token = url.searchParams.get('Token');
    if (token) {
      login(token, 35000, false, false);
      navigate('/Overview/')
    }
  }, [])

  useEffect(() => {
    if (CBC_ENV === 'remote') {
      if (process.env.NODE_ENV !== 'development') {
        axios
          .get(`${benefitPortalURL}/Token.ashx?v=${random()}`)
          .then((res) => {
            login(res.data.access_token, res.data.expires_in, false, false);
            navigate('/Overview/')
          })
          .catch(() => {
            navigate(home)
          });
      }
    }
  }, [CBC_ENV, home, login])

  useEffect(() => {
    document.body.classList.add('login');
  }, [])

  const handleUsernameChange = (e) => {
    e.persist()
    const Error = '';
    setstate(prev => ({ ...prev, Username: e.target.value, Error }));
  }
  const handlePasswordChange = (e) => {
    e.persist()
    const Error = '';
    setstate(prev => ({ ...prev, Password: e.target.value, Error }));
  }
  const handleLogin = (event) => {
    // process.env.REACT_APP_ENV
    event.preventDefault();

    let Error = '';
    // Validate Data
    const re = /^[a-zA-Z]{2}[0-9]{8}$/;
    if (!re.test(String(state.Username).toLowerCase())) Error = 'Invalid Username';

    if (state.Password.length !== 6 && state.Password.length < 8) Error = 'Invalid Password';

    // check for any errors and set state
    if (Error.length > 0) {
      setstate(prev => ({ ...prev, Error }));
      return false;
    }

    setstate(prev => ({ ...prev, Loading: true }));

    // Perform API call to data source

    const data = qs.stringify({
      userName: state.Username,
      password: state.Password,
      confirmPassword: state.Password,
      grant_type: 'password',
    });

    // Example of using a variable inside of the HTML file to use inside of a react application
    // if(window.LoginURL)
    //   LoginURL = window.LoginURL
    axios
      .post(`${loginURL}&V=${random()}`, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      })
      .then((res) => {
        // Log the user in
        login(res.data.access_token, res.data.expires_in, state.Username, state.Password);
        navigate('/Overview/')
        setstate(prev => ({ ...prev, Error: '', Loading: false }));
      })
      .catch(() => {
        setstate(prev => ({ ...prev, Error: 'Invalid Login Credentials', Loading: false }));
      });
  }

  if (CBC_ENV === 'remote' || token) {
    return (
      <Layout noNav>
        <SEO title="Home" />
        <div id="content-bg" className="contentbg-gradient">
          <div className="content-outline-998 viewRequest" style={{ textAlign: 'center' }}>
            <strong style={{ fontSize: '1.5em' }}>Checking your credentials...</strong>
            <br />
            <img
              src={`${pathPrefix}/styles/images/LoadingAnim.gif`}
              width="30%"
              alt=""
            />
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout noNav noHeader>
      <SEO title="Home" />
      <div style={{ width: '100%', height: 20 }}></div>
      <div className="loginwrapper" style={{ clear: 'both' }}>
        <div className="loginpic">
          <div className="loginPicBullets">
            <h3>Providing Easy Access to:</h3>
            <ul className="fa-ul RevealRight" style={{ fontSize: '1em' }}>
              <li><i className="fa-li fa color1 fa-check-circle white"></i>FMLA Forms &amp; Policies</li>
              <li><i className="fa-li fa color1 fa-check-circle white"></i>Leave Laws by State</li>
              <li><i className="fa-li fa color1 fa-check-circle white"></i>Online Leave Submission</li>
              <li><i className="fa-li fa color1 fa-check-circle white"></i>Leave Tracking &amp; Approval</li>
            </ul>
          </div>
        </div>
        <div className="loginpanel">
          <p className="clear">&nbsp;</p>
          <div className="loginLogo">
            <img
              src={`${pathPrefix}/styles/images/login_logo.png`}
              width="100%"
              alt=""
            />
          </div>
          <p className="clear">&nbsp;</p>
          <div className="log_feil_main">
            <p
              className="EmpLoginPgTitle"
            >
              Employee Login:
            </p>
            <div className="q1">
              <a
                className="normalTip"
                style={{ display: 'block', cursor: 'default' }}
                title="Your User Name is the first initials of your first name and last name followed by your date of birth (mmddyyyy) with no spaces in between. (For example: If John Smith was born on September 17, 1971, his ID would be JS09171971)"
              >
                  <span
                    style={{
                      color: '#fff',
                      fontSize: '1.3em',
                      display: 'inline-block',
                      paddingTop: '.15em',
                      pointerEvents: 'none',
                    }}
                  >
                    ?
                  </span>
              </a>
            </div>
            <div className="in_log_feild">
              <input
                type="text"
                name="txtnme"
                className="text_in"
                placeholder="Username"
                value={state.Username}
                onChange={handleUsernameChange}
                disabled={state.Loading}
              />
            </div>
            <div className="in_log_feild" style={{ paddingBottom: 16 }}>
              <input
                type="password"
                name="txtnme"
                className="text_in_pass"
                placeholder="Password"
                value={state.Password}
                onChange={handlePasswordChange}
                disabled={state.Loading}
              />
              <div className="q2">
                <a
                  className="normalTip"
                  style={{ display: 'block', cursor: 'default' }}
                  title="Your Password: If this is your first time logging into the Leave Center, you will need to enter your temporary password. Your temporary password is the last six digits of your social security number. (For example: If John Smith’s full SS number is 123-45-6789, the password is 456789). After logging in, you will be prompted to change your password"
                >
                    <span
                      style={{
                        color: '#fff',
                        fontSize: '1.3em',
                        display: 'inline-block',
                        paddingTop: '.15em',
                        pointerEvents: 'none',
                      }}
                    >
                      ?
                    </span>
                </a>
              </div>
            </div>
            <a
              id="loginbutton"
              onClick={handleLogin}
              style={{ cursor: 'pointer' }}
            >
              {state.Loading ? 'Loading...' : 'Login  '}
              {!state.Loading && (
                <i
                  className="fa fa-angle-double-right"
                  aria-hidden="true"
                />
              )}
            </a>
            <a
              id="loginbutton"
              onClick={(e) => {
                e.preventDefault()
                window.location.href = ssoURL
              }}
              style={{ cursor: 'pointer' }}
            >
              {state.Loading ? 'Loading...' : 'SSO Login  '}
              {!state.Loading && (
                <i
                  className="fa fa-angle-double-right"
                  aria-hidden="true"
                />
              )}
            </a>
            {/*<a*/}
            {/*  href={forgotPasswordURL}*/}
            {/*  style={{*/}
            {/*    color: '#00ADEE',*/}
            {/*    fontSize: '1.25em',*/}
            {/*    paddingTop: '.5em',*/}
            {/*    paddingRight: '1em',*/}
            {/*    display: 'block',*/}
            {/*    float: 'left',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Forgot Password?*/}
            {/*</a>*/}
            <div className="loginError"><span>{state.Error ? 'Error: ' : ''}</span>{state.Error}</div>
            <p>&nbsp;</p>
          </div>
          <div className="loginPicBullets2">
            <h3>Providing Easy Access to:</h3>
            <ul className="fa-ul RevealRight" style={{ fontSize: '1em' }}>
              <li><i className="fa-li fa color1 fa-check-circle white"></i>Plan Information &amp; Forms</li>
              <li><i className="fa-li fa color1 fa-check-circle white"></i>Dependent &amp; Beneficiary Info</li>
              <li><i className="fa-li fa color1 fa-check-circle white"></i>Links to Health Carrier Sites</li>
              <li><i className="fa-li fa color1 fa-check-circle white"></i>Discounts</li>
              <li><i className="fa-li fa color1 fa-check-circle white"></i>Leave Request</li>
            </ul>
          </div>
          <div className="loginContent">
            <p style={{ clear: 'both', marginTop: '3em' }}>The Leave Center is designed to give information and documents
              necessary for you to successfully manage your Leave of Absence. If you require time off for three or more
              consecutive days due to any illness or pregnancy for you or your family members, you can use our online
              leave form to submit a request for leave.</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
